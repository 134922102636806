import Head from 'next/head';
import { useEffect, useState, useRef } from 'react';
import ImageModule from 'next/image';
import styles from 'styles/Home.module.scss';
import Link from 'next/link';
import hero1 from 'assets/images/ab.png';
import hero2 from 'assets/images/ai.png';
import hero3 from 'assets/images/cro.png';
import hero4 from 'assets/images/exp.png';
import LogoAdobeTarget from 'assets/misc/adobe-target.svg';
import LogoVWO from 'assets/misc/vwo.svg';
import LogoOptimizely from 'assets/misc/optimizely.svg';
import LogoKameleoon from 'assets/misc/kameleoon.svg';
import FeatureCube from 'assets/svg/feature-cube.svg';
import PurpleCheckmark from 'assets/svg/purple-checkmark.svg';


export default function Home() {
    const Image = ImageModule.default;
	const imageRefs = useRef([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleExpandDropdown = (e) => {
		e.currentTarget.getAttribute('aria-expanded') === 'true' ? e.currentTarget.setAttribute('aria-expanded', false) : e.currentTarget.setAttribute('aria-expanded', true);
	}
	 
	useEffect(() => {
		setTimeout(() => {
			if (imageRefs.current.length === 0) return;
			imageRefs.current[currentIndex]?.removeAttribute('aria-label')
			const nextIndex = (currentIndex + 1) % 4;
			setCurrentIndex(nextIndex);
			imageRefs.current[nextIndex]?.setAttribute('aria-label', 'true')
		}, 7000)
	}, [currentIndex]);
	
  return (
    <>
        <Head>
            <title>
                Experilyze.com - AI-Generated A/B Test Experiments
            </title>
		  	<meta name='description' content='AI-Driven generated A/B Test Experiments' />
        </Head>
		<header className='hero-left-text-right-img'>
			<div>
				<div>
					<Image src={ hero1 } alt='slider' fill priority={ true } aria-label='true' ref={ ele => imageRefs.current[0] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
					<Image src={ hero2 } alt='slider' fill priority={ true } ref={ ele => imageRefs.current[1] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
					<Image src={ hero3 } alt='slider' fill priority={ true } ref={ ele => imageRefs.current[2] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
					<Image src={ hero4 } alt='slider' fill priority={ true } ref={ ele => imageRefs.current[3] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
				</div>
				<div>
					<h1>Boost Your Experimentation Strategy with AI</h1>
					<p>Leverages AI to generate efficient A/B testing experiments for your digital campaigns.</p>
					<p><Link href='/contact' className='btn-cta'>Get Started</Link></p>
				</div>
			</div>
		</header>
		<div className='main-content'>
			<section className={ styles.trusted }>
				<h1>Trusted Tools</h1>
				<ul>
                    <li><LogoAdobeTarget /></li>
                    <li><LogoOptimizely /></li>
                    <li><LogoVWO /></li>
                    <li><LogoKameleoon /></li>
                </ul>
			</section>
			<section id="features" className={ styles.features }>
				<h1>Features</h1>
				<article>
					<div>
						<h2>
                            Unlock the Full Potential of the Artificial Intelligence
						</h2>
                        <h3>
                            Leverage the power of AI to revolutionize your A/B testing and optimization process.
                        </h3>
						<ul>
							<li>
                                <FeatureCube />
								<strong>AI-Driven Experimentation</strong> 
                                <p>Leverage cutting-edge artificial intelligence to create, optimize, and analyze A/B test experiments with unparalleled accuracy and speed.</p>
							</li>
							<li>
                                <FeatureCube />
								<strong>No-Code Solution</strong> 
                                <p>With our easy-to-use Chrome extension, non-technical users can effortlessly set up and preview A/B tests without needing to write a single line of code.</p>
							</li>
							<li>
                                <FeatureCube />
								<strong>Full Integration with Existing Tools</strong>
                                <p>Seamlessly integrate Experilyze with popular marketing platforms and analytics tools, such as Adobe Target and Ooptimizely to enhance your testing capabilities.</p>
							</li>
							<li>
                                <FeatureCube />
								<strong>Scalable for Any Business Size</strong>
                                <p>From small startups to enterprise-level organizations, Experilyze grows with your business, offering flexible pricing plans to suit your needs.</p>
							</li>
							<li>
                                <FeatureCube />
								<strong>Effortless Test Creation</strong>
                                <p>Our AI generates A/B tests for you, removing the need for complex setup or coding expertise.</p>
							</li>
							<li>
                                <FeatureCube />
								<strong>Smart Experiment Suggestions</strong>
                                <p>Based on your data, our AI recommends high-impact tests to drive conversion growth faster than ever.</p>
							</li>
						</ul>
					</div>
				</article>
			</section>
			<section id="plans" className={ styles.plans + ' ' + 'full-bg-white' + ' ' + ' full-content-list' }>
                <h1>Plans</h1>
				<article>
                    <div>
                        <h2>Choose the Right Plan for Your Business</h2>
                        <h3>Whether you're a small business, a growing team, or a large enterprise, there's a plan designed to fit your needs. Each option empowers you with AI-driven experimentation, customized to match the scale of your operations.</h3>
                        <ul>
                            <li>
                                <strong>Basic</strong>
                                <p>For individuals or small teams starting with A/B testing.</p>
                                <Link href='/contact'>Get Started</Link>
                                <ul>
                                    <li>
                                        <PurpleCheckmark />
                                        No-code A/B test creation
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Limited number of experiments per month
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Max 1 account
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Medium</strong>
                                <p>For growing businesses ready to take their experimentation to the next level.</p>
                                <Link href='/contact'>Get Started</Link>
                                <ul>
                                    <li>
                                        <PurpleCheckmark />
                                        No-code A/B test creation
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Increased experiment limits
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Advanced tool for continuous development
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Max 5 accounts
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Enterprise</strong>
                                <p>For large organizations seeking full automation and unlimited testing power.</p>
                                <Link href='/contact'>Get Started</Link>
                                <ul>
                                    <li>
                                        <PurpleCheckmark />
                                        No-code A/B test creation
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Unlimited experiments
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Advanced tool for continuous development
                                    </li>
                                    <li>
                                        <PurpleCheckmark />
                                        Unlimited accounts
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </article>
			</section>
            <section id="faq" className={ styles.faq + ' ' + 'full-content-list' }>
				<h1>Frequently Asked Questions</h1>
				<article>
					<ul>
						<li onClick={ handleExpandDropdown } >
							<p>What is AI-powered A/B testing and how does it work?</p>
							<p>AI-powered A/B testing automates the process of creating, running, and analyzing experiments to optimize website or app performance. Instead of manually setting up variations and analyzing results, AI generates test scenarios based on data, runs the experiments, and provides insights. It continuously learns from user interactions, allowing for smarter test recommendations and real-time optimizations. This reduces guesswork and makes it easier to improve conversions and user experience without requiring extensive manual intervention.</p>
						</li>
						<li onClick={ handleExpandDropdown } >
							<p>Do I need coding experience to use the platform?</p>
							<p>No, you don't need any coding experience to use the platform. Our tool is designed to be accessible for all users, including non-technical individuals. With a simple, no-code interface, you can easily set up and run A/B tests using AI-powered features. The platform automates the entire process—from experiment creation to analysis—allowing you to focus on strategy and results without worrying about the technical details.</p>
						</li>
						<li onClick={ handleExpandDropdown } >
							<p>What types of experiments can I run with this tool?</p>
							<p>With this tool, you can run a wide range of experiments, including traditional A/B tests, multivariate tests, and split URL tests. You can experiment with various elements of your website or app, such as headlines, images, calls to action, layouts, and more. The platform also supports personalization experiments, allowing you to test different versions for specific user segments. AI will help you identify the most impactful areas for testing, making it easy to optimize conversions and user experience.</p>
						</li>
						<li onClick={ handleExpandDropdown } >
							<p>How does the free trial or early access work?</p>
							<p>The free trial or early access period allows you to explore the platform's features without any commitment. During this time, you’ll have access to core functionality, including the ability to create and run a limited number of A/B tests. It’s designed to give you a feel for how AI-powered experimentation can benefit your business. Once the trial ends, you can choose from one of our subscription plans to continue using the tool and unlock additional features based on your needs.</p>
						</li>
						<li onClick={ handleExpandDropdown } >
							<p>What happens if I reach my experiment limit on the Basic plan?</p>
							<p>If you reach your experiment limit on the Basic plan, you’ll have a few options. You can either upgrade to a higher-tier plan, such as Pro or Enterprise, which offers more experiment capacity and additional features, or wait until the next billing cycle when your experiment limits reset. During this time, you’ll still have access to your existing experiments and data, but you won’t be able to create new tests until you upgrade or the limits reset.</p>
						</li>
						<li onClick={ handleExpandDropdown } >
							<p>How does pricing work for larger teams or enterprises?</p>
							<p>For larger teams or enterprises, we offer custom pricing based on your specific needs. The Enterprise plan provides unlimited experiments, full automation, advanced integrations, and dedicated support. We work closely with you to create a pricing structure that aligns with your organization’s size, requirements, and goals. You can request a demo or contact our sales team to discuss custom plans tailored to your business, ensuring you have the resources and tools needed for large-scale experimentation and optimization.</p>
						</li>
					</ul>
				</article>
			</section>
			<section className='sbs-banner'>
				<article>
					<div>
						<h1>Ready to Supercharge Your A/B Testing?</h1>
						<Link href='/contact' className='btn-cta'>Get Early Access</Link>
					</div>
					<div>
						<p> Don’t miss out on the opportunity to streamline your experimentation process with AI-powered insights. Join early adopters in transforming the way you optimize and grow your business.</p>
					</div>
				</article>
			</section>
      </div>
    </>
  )
}
